/* ---------------------------

	BUTTONS

--------------------------- */

.baseButton() {
  .fontSize(14px);
  padding: 8px 32px;
  position: relative;
  min-height: 36px;
  border-radius: 5px;
  font-weight: 400;
  .animate();
  outline: none;
}


.btn {
  .baseButton();

  border: 5px solid red;

  &.btn-sm {
    padding: 5px 12px;
    min-height: 28px;
    .fontSize(12px);

    &.btn-rounded {
      border-radius: 14px;
    }
  }
  &.btn-xs {
    .fontSize(10px);
    height: 12px !important;
    padding: 0px 10px;
    &.btn-rounded {
      border-radius: 30px;
    }
  }

  &.btn-lg {
    .fontSize(16px);
    padding: 15px 30px;
    min-height: 54px;

    &.btn-rounded {
      border-radius: 27px;
    }
  }

  &.btn-outlined {
    border: 1px solid @light-grey;
    border-radius: 17px;
    color: @grey;
  }

  /* ------------------------- *
   |       BUTTON COLORS       |
   *-------------------------- */
  &.btn-light {
    border: 1px solid #D4D4D4;
    background: #fff !important;
    color: @grey !important;

    &:hover, &:focus {
      background: darken(#fff, 5%) !important;
      color: @grey !important;
    }
  }

  &.btn-primary {
    border: none;
    background-color: @primary-color !important;
    color: #fff !important;

    &:hover, &:focus, &:active {
      background-color: darken(@primary-color, 5%) !important;
      border-color: lighten(@primary-color, 5%) !important;
      color: #fff !important;
      box-shadow: 0 0 0 0.2rem rgba(lighten(@primary-color, 5%), 0.5) !important;
    }
  }

  &.btn-blue {
    border: none;
    background-color: @blue !important;
    color: #fff !important;

    &:hover, &:focus {
      background-color: darken(@blue, 5%) !important;
      color: #fff !important;
    }
  }

  &.btn-reverse {
    border: 2px solid @primary-color;
    background-color: @white !important;
    color: @primary-color !important;

    &:hover, &:focus {
      border: 2px solid darken(@primary-color, 5%);
      color: darken(@primary-color, 5%) !important;
    }
  }

  &.btn-green {
    border: none;
    background-color: @green !important;
    color: #fff !important;

    &:hover, &:focus {
      background-color: darken(@green, 5%) !important;
      color: #fff !important;
    }
  }
}

.ant-modal-body{
  text-align: inherit!important;
}


.add-btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: fade(@green, (.3*100));
  position: relative;

  &:before {
    content: "";
    position: absolute;
    margin: 10px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: @green url('../../../img/icon-plus-white.svg') no-repeat center;
    background-size: 16px;
  }
}


.add-button-container {
  float: left;
  height: 54px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;

  .add-btn {
    float: left;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }

  span {
    float: left;
    margin-top: 10px;
    line-height: 54px;
    color: @primary-color;
    text-transform: uppercase;
  }

  .dropdown-list {
    margin-top: 20px;
  }
}

.ant-back-top-content {
  background-color: @primary-color !important;
}
.ant-back-top-content:hover {
  opacity: 0.6;
}

.content-right-section {
  .button-select-container {
    float: right;
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @orange-rgba-70;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px @orange-rgba-0;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @orange-rgba-0;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @red-rgba-70;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px @red-rgba-10;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @red-rgba-10;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @green-rgba-70;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px @green-rgba-0;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 @green-rgba-0;
  }
}
.glow {
  border-radius: 100px;

  > .glow {
    padding: 7px 0 6px 0;
    box-shadow: 0 0 0 4px fade(@green, (0.50*100));

    > .glow {
      box-shadow: 0 0 0 8px fade(@green, (0.30*100));

      > .glow {
        box-shadow: 0 0 0 12px fade(@green, (0.15*100));
      }
    }
  }
}

.entities-search-top-container{
  .search-add-field{
    background-color: #6b6b6b;
    color:white;
    margin-top: 2px;
    margin-right: 5px;
  }
  .search-add-field:hover{
    background-color: @sub-category-text;
    color:white;
    border-color: @white;
  }

}

.quick-action-button-container span{
  padding-left: 5px;
  padding-right: 5px;
}
