@import "SliderActions/_sliderActions";
@import "_pagination";
@import "_resultsHeaderForTable";

// TODO: If possible delete as much as possible from below!

tr:hover {
  background: none;
}
tr:hover {
  background: none!important;
}

.td:hover {
  background: none!important;
}
.table-container {
    float: left;
    width: 100%;
    margin-bottom: 40px;

    .cherrypick {
      cursor: pointer;
    }

    &.main-padding {
      padding: 40px 30px 30px 30px;
    }




    .contact-section-top {
      float: left;
      width: 100%;
      margin-bottom: 20px;

      ul.section-actions {
        float: right;
        margin: -7px 0 0;
        list-style: none;
        padding: 0;

        li {
          float: left;
          margin: 3px;
          padding: 0;
          position: relative;
          cursor: pointer;
        }

        li.action {
          span {
            float: left;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            .animate();

            &.action-more {
              background: #ECECEC url('../../../img/icon-more.svg') no-repeat center;
            }

            &:hover {
              background-color: darken(#ececec, 5%);
            }
          }

          .dropdown-list {
            right: -13px;
            left: auto;
          }
        }
      }

      h2 {
        margin-bottom: 0;
        float: left;
      }
    }


  .table-responsive {
    table {
      background-color: @white;
      font-family: @font;
      border-radius: 6px;

      tr > td:first-child tr > th:first-child {
        padding-left: 10px;
      }

      tr > td {
        padding: 16px 16px;
      }
    }

    .global-database {
      border-left: 4px solid @blue;
    }
    .shared-status {
      border-left: 4px solid @action-color;
    }
    .fav-line {
      border-left: 4px solid @light-green;
    }

    .inactive-status{
      border-left: 4px solid @light-grey;
    }

  }

}
.button-dcr-actions{
  .ant-btn{
    margin-left: @micro;
  }
}

.activity-nested-table-container{


  .ant-table{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 30px !important;
  }
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e9e9e9;
  padding: 2px!important;
}
.ant-table-thead > tr > th {
  font-weight: 800;
  border-bottom: 1px solid #e9e9e9;

}
.ant-pagination.mini .ant-pagination-item {
  border-radius: 50px;
  background-color: @primary-color;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-selector{
  border-radius: 50px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input{
  border-radius: 50px;
}
.ant-table{
  .width-restriction{
    max-width: 350px;
  }
}
.test{
  border-right: solid 1px @border-color;
}


.global-database{
  border-left: solid 2px @light-blue;
}

.react-grid-layout{

}
.ant-table-wrapper .ant-table-column-sorter-up.active,.ant-table-wrapper .ant-table-column-sorter-down.active {
  color: @action-color;
}
.ant-tabs-top > .ant-tabs-nav{
  border-bottom: 1px solid @heading-color-1
}
a:hover{
  text-decoration: underline;
}
