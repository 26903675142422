.custom-field-tag {
  .ant-tag {
    margin-bottom: 5px;
  }
}
.custom-field-multi-select {
  position: relative;
  padding: 0 5px;
  background-color: @white;
  border: 1px solid @border-color-base;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  .custom-multi-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
    &::after {
      display: inline-block;
      width: 0;
      margin: 3px 0;
      line-height: 22.4px;
      content: '\a0';
    }
    .custom-multi-select-selection-overflow-item {
      flex: none;
      align-self: center;
      max-width: 100%;
      .custom-multi-select-selection-item {
        position: relative;
        display: flex;
        flex: none;
        box-sizing: border-box;
        max-width: 100%;
        height: 22.4px;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 20.4px;
        background: @collapse-content-bg;
        border: 1px solid @custom-multi-select-item-border;
        border-radius: 4px;
        cursor: default;
        transition: font-size 0.3s, line-height 0.3s, height 0.3s;
        -webkit-user-select: none;
        user-select: none;
        margin-inline-end: 4.8px;
        padding-inline-start: 8px;
        padding-inline-end: 4px;
        .custom--multi-select-selection-item-content {
          display: inline-block;
          margin-right: 4px;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
        }
      }
    }
  }
}